import { CopyOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';
import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { StreamQrPayServiceClient } from './lib/service_stream_qr_pay_grpc_web_pb';
import { GetQRpayReq } from './lib/service_stream_qr_pay_pb';

import {
  Alert,
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Input,
  Modal,
  notification,
  Row,
  Typography,
} from 'antd';
import Countdown from 'react-countdown';
import ACB from '../../assets/images/logo-bank/ACB.png';
import MB from '../../assets/images/logo-bank/MB.png';
import { banks } from './lib/vietnam-bank';
import { useHistory } from 'react-router-dom';
// Add a console.log to check if the 'GetQRpayReq' is exported from the module
console.log(GetQRpayReq);
const { Title, Text } = Typography;
const SIGNAL_FAIL = 'fail';
const SIGNAL_QR_CODE = 'qrcode';
const SIGNAL_WAIT = 'wait';
const SIGNAL_END = 'end';

const CKPayStatus_Success = 'ck.success';
const CKPayStatus_Over = 'ck.over.fund';
const CKPayStatus_Insufficient = 'ck.insufficient.fund';

var LOCALHOST = false;
var url = 'https://dev-test.safeweb.app';
if (LOCALHOST) {
  url = 'http://localhost:3002';
}
var client = new StreamQrPayServiceClient(url, null, null);
var stream: any = null;
var enableTimeout = true;

function SafePayment() {
  const history = useHistory();
  // const [Clock, setClock] = useState('00:00:00');
  // const [Signal, setSignal] = useState('init');
  // const [Message, setMessage] = useState('wait response');

  // const [Bank, setBank] = useState('');
  const [BankBin, setBankBin] = useState('');
  const [AccNumber, setAccNumber] = useState('0');
  const [AccName, setAccName] = useState('');
  const [Amount, setAmount] = useState('0');
  const [Purpose, setPurpose] = useState('pay to safeweb');
  const [ImageSrc, setImageSrc] = useState('');

  const [messageBox, setMessageBox] = useState<any>({});

  // const [Code, setCode] = useState('');
  // const [Status, setStatus] = useState('');
  // const [Data, setData] = useState('');

  const getStats = () => {
    // calling http/1
    var request = new GetQRpayReq();

    request.setAdminId('phanhai');
    request.setAdminTitle('Mr');
    request.setAdminName('Phan Hải');
    request.setAdminPhone('098xxx');
    request.setAdminCountry('Vietnam');
    request.setOrderTotal(50000);
    request.setOrderInfo('QR pay');
    request.setProductName('standard package');
    request.setProductCode('123');
    request.setProductPrice(50000);
    request.setProductUnit('month');
    request.setProductDiscount(0);
    request.setProductTax(0);
    request.setProductQuantity(50000);
    request.setProductDescription('upgrade');
    request.setCoupon('SAFEWEB2024');

    stream = client.getQRpay(request, {});

    console.log('request');
    console.log(request);

    console.log('stream');
    enableTimeout = true;
    var chuyenKhoan = '';

    // Start listening on the data event, this is the event that is used to notify that new data arrives
    stream.on('data', function (response: any) {
      var logTime = new Date();
      var timeString =
        logTime.getHours() +
        ':' +
        logTime.getMinutes() +
        ':' +
        logTime.getSeconds();
      console.log(timeString);
      console.log(response);

      // Convert Response to Object
      var cmdRes = response.toObject();

      if (cmdRes.signal === SIGNAL_FAIL) {
        console.log(
          'Thông báo lỗi hệ thống cho user để họ tự tạo lại page này.'
        );
        setMessageBox({
          title: 'Có lỗi thao tác',
          content: 'Bạn có muốn thực hiện lại không?',
          isOpen: true,
        });
      } else if (cmdRes.signal === SIGNAL_QR_CODE) {
        console.log('Đặt timeout cho page này 10 phút. Sau 10 phút hủy mã QR.');

        // refer: https://www.vietqr.io/en/intro
        // https://img.vietqr.io/image/<BANK_ID>-<ACCOUNT_NO>-<TEMPLATE>.png
        // ?amount=<AMOUNT>&addInfo=<DESCRIPTION>&accountName=<ACCOUNT_NAME>
        let imgSrc = 'https://img.vietqr.io/image/';
        imgSrc += cmdRes.bankInfo.bankKey;
        imgSrc += '-' + cmdRes.accountNumber;
        imgSrc += '-compact.png';
        imgSrc += '?amount=' + cmdRes.payAmount;
        imgSrc += '&addInfo=' + cmdRes.payPurpose;
        imgSrc += '&accountName=' + cmdRes.accountName;

        let encodedUrl = encodeURI(imgSrc);
        console.log(encodedUrl);
        setImageSrc(encodedUrl);
      } else if (cmdRes.signal === SIGNAL_WAIT) {
        console.log(
          'Page không làm gì. Hiển thị đồng hồ đếm ngược 10 phút về 0 để user hiểu timeout.'
        );
        if (enableTimeout) {
          var tenMinutes = 10 * 60 * 1000;
          setTimeout(function () {
            cancelStream('Timeout to CANCEL stream');
            enableTimeout = false;
            // setMessageBox({
            //   title: 'Hết thời gian giao dịch',
            //   content: 'Bạn có muốn thực hiện lại giao dịch không?',
            //   isOpen: true,
            // });
          }, tenMinutes);
        }
      } else if (cmdRes.signal === SIGNAL_END) {
        console.log('Giao dịch chuyển khoản đã được thực hiện.');

        if (cmdRes.payStatus === CKPayStatus_Success) {
          console.log('Chuyển khoản thành công.');
          // cancelStream('client CANCEL stream');
          chuyenKhoan = 'Chuyển khoản thành công.';

          // history.push('/billing');
        } else if (cmdRes.payStatus === CKPayStatus_Over) {
          chuyenKhoan = 'Chuyển khoản thừa tiền.';
          // cancelStream('client CANCEL stream');

          // history.push('/billing');
        } else if (cmdRes.payStatus === CKPayStatus_Insufficient) {
          console.log('Chuyển khoản thiếu tiền.');
          chuyenKhoan = 'Chuyển khoản thiếu tiền.';
        } else {
          console.log('Liên hệ với SafeWeb để giải quyết sự cố.');
          chuyenKhoan = 'Liên hệ với SafeWeb để giải quyết sự cố.';
        }
        cancelStream('client CANCEL stream');
        // setMessageBox({
        //   title: 'Bạn đã thực hiện giao dịch',
        //   content: chuyenKhoan,
        //   isOpen: true,
        // });
        success(chuyenKhoan);
      }
      console.log('messageBox', messageBox);

      // Set our variable values
      // setClock(timeString);
      // setSignal(cmdRes.signal);
      // setMessage(cmdRes.message);

      // setBank(cmdRes.bankInfo.bankKey);
      setAccNumber(cmdRes.accountNumber);
      setAccName(cmdRes.accountName);
      setAmount(cmdRes.payAmount);
      setPurpose(cmdRes.payPurpose);
      setBankBin(cmdRes.bankInfo.bankBin);

      // setCode(cmdRes.payCode);
      // setStatus(cmdRes.payStatus);
      // setData(cmdRes.payData);
    });
    stream.on('end', function () {
      cancelStream('END stream by server');
    });

    stream.on('error', function (err: any) {
      cancelStream(
        `ERROR in stream: code = ${err.code}` + `, message = "${err.message}"`
      );
    });
  };
  const cancelStream = (message: any) => {
    if (stream != null) {
      stream.cancel();
      stream = null;
      console.log(message);
    }
  };

  // useEffect will make this trigger on component start
  useEffect(() => {
    getStats();
  }, []);

  const [bankName, setBankName] = useState<any>();

  useEffect(() => {
    const bankInfo = banks.find((bank) => bank.bankId === BankBin);
    if (bankInfo) {
      setBankName({
        title: bankInfo.shortName,
        src: BankBin === '970422' ? MB : ACB,
        description: bankInfo.vn_name,
      });
    }
  }, [BankBin]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
    getStats();
    setIsModalOpen(false);
  };

  const handleOkMessageBox = () => {
    // setMessageBox({ ...messageBox, isOpen: false });
    cancelStream('User CANCEL stream');
    history.push('/billing');
  };

  const handleCancel = () => {
    // setIsModalOpen(false);
    cancelStream('User CANCEL stream');
    history.push('/billing');
  };
  const success = (content: string) => {
    Modal.success({
      content,
      onOk: handleOkMessageBox,
    });
  };

  return (
    <div className="App">
      <Row justify="center" style={{ marginTop: '20px' }}>
        <Col lg={16} md={18} xs={24}>
          <Card>
            <Row gutter={16} align="middle">
              <Col
                md={{ span: 0 }}
                xs={{ span: 24 }}
                style={{
                  // display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Alert
                  message={
                    <Title level={4} style={{ color: '#52c41a' }}>
                      GIAO DỊCH TỰ ĐỘNG
                    </Title>
                  }
                  description={
                    <Text>
                      Giao dịch thanh toán của bạn sẽ ĐƯỢC XÁC NHẬN TỰ ĐỘNG. Nếu
                      bạn không quét được mã VietQR, vui lòng
                      <b> NHẬP ĐÚNG SỐ TIỀN và NỘI DUNG</b> chuyển khoản.
                    </Text>
                  }
                  type="success"
                  showIcon
                />
              </Col>
              <Col
                md={{ span: 10 }}
                xs={{ span: 24 }}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Title level={5} style={{ textAlign: 'center' }}>
                  Dùng app ngân hàng quét mã
                </Title>
                <img
                  src={ImageSrc}
                  alt="QR Image"
                  width="300"
                  height="300"
                  style={{ textAlign: 'center' }}
                />
                {/* <Countdown date={Date.now() + 600000} renderer={renderer} />, */}
                <div style={{ padding: '5px 0px' }}>
                  <Countdown
                    key={isModalOpen ? 'modal' : 'countdown'}
                    date={Date.now() + 600000}
                    onComplete={() => setIsModalOpen(true)}
                    renderer={({ hours, minutes, seconds, completed }) => {
                      if (completed || isModalOpen) {
                        // Render a complete state
                        return '00:00';
                      } else {
                        // Render a countdown
                        return (
                          <span>{`${minutes > 9 ? minutes : '0' + minutes}: ${
                            seconds > 9 ? seconds : '0' + seconds
                          }`}</span>
                        );
                      }
                    }}
                  />
                </div>
                <Button onClick={() => saveAs(ImageSrc, `${Purpose}.jpg`)}>
                  TẢI QR VỀ
                </Button>
              </Col>
              <Col md={{ span: 14 }} xs={{ span: 24 }}>
                <Row>
                  <Col xs={{ span: 0 }} md={{ span: 24 }}>
                    <Alert
                      message={
                        <Title level={4} style={{ color: '#52c41a' }}>
                          GIAO DỊCH TỰ ĐỘNG
                        </Title>
                      }
                      description={
                        <Text>
                          Giao dịch thanh toán của bạn sẽ ĐƯỢC XÁC NHẬN TỰ ĐỘNG.
                          Nếu bạn không quét được mã VietQR, vui lòng
                          <b> NHẬP ĐÚNG SỐ TIỀN và NỘI DUNG</b> chuyển khoản.
                        </Text>
                      }
                      type="success"
                      showIcon
                    />
                  </Col>
                  <Col span={24}>
                    <Divider />
                    <Title level={4} style={{ textAlign: 'center' }}>
                      THÔNG TIN TÀI KHOẢN NHẬN
                    </Title>
                    {bankName && (
                      <Row gutter={16}>
                        <Col
                          md={{ span: 4, offset: 3 }}
                          xs={{ span: 6, offset: 0 }}
                        >
                          <Avatar size={64} src={bankName.src} />
                        </Col>
                        <Col
                          md={{ span: 14 }}
                          xs={{ span: 18 }}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <div>
                            Ngân hàng: <Text strong>{bankName.title}</Text>
                          </div>
                          <Text strong>{bankName.description}</Text>
                        </Col>
                      </Row>
                    )}

                    <div style={{ marginTop: '10px' }}>
                      <Text strong>CHỦ TÀI KHOẢN: </Text>
                      <Input
                        value={AccName}
                        readOnly
                        suffix={
                          <CopyToClipboard text={AccName}>
                            <CopyOutlined />
                          </CopyToClipboard>
                        }
                      />
                    </div>
                    <div style={{ marginTop: '10px' }}>
                      <Text strong>SỐ TÀI KHOẢN: </Text>
                      <Input
                        value={AccNumber}
                        readOnly
                        suffix={
                          <CopyToClipboard text={AccNumber}>
                            <CopyOutlined />
                          </CopyToClipboard>
                        }
                      />
                    </div>
                    <div style={{ marginTop: '10px' }}>
                      <Text strong>SỐ TIỀN (đ): </Text>
                      <Input
                        // value={`${Amount.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}đ`}
                        value={Amount}
                        readOnly
                        suffix={
                          <CopyToClipboard text={Amount}>
                            <CopyOutlined />
                          </CopyToClipboard>
                        }
                      />
                    </div>
                    <div style={{ marginTop: '10px' }}>
                      <Text strong>NỘI DUNG: </Text>
                      <Input
                        value={Purpose}
                        readOnly
                        suffix={
                          <CopyToClipboard text={Purpose}>
                            <CopyOutlined />
                          </CopyToClipboard>
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Modal
        title="Phiên giao dịch hết giờ!"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Tiếp tục"
        cancelText="Hủy bỏ"
      >
        <p>Bạn có muốn tiếp tục không?</p>
      </Modal>
      {/* <Modal.success
        title={`messageBox.title`}
        visible={messageBox.isOpen}
        onOk={handleOkMessageBox}
        onCancel={handleCancel}
        okText="Ok"
        cancelText="Hủy bỏ"
      >
        <p>{messageBox.content}</p>
      </Modal.success> */}
    </div>
  );
}

export default SafePayment;
